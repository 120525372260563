/*
  Entry point for the Korean site
*/

$font-family-base: 'Nanum Gothic', sans-serif;
$headings-font-family: 'Nanum Gothic', sans-serif;

$font-size-base: 1.2rem;
$h1-font-size: $font-size-base * 2;
$h1-font-size-lg: 4rem;
$nav-font-size: $font-size-base;

/* $h4-font-size made to be the same size as h5 since h5 is never used anyway.
   The $h4-font-size is used for the hamburger menu main items. */
$h4-font-size: $font-size-base * 1.25;

/* $h5-font-size is used for the product names on the Products page. */
$h5-font-size: $font-size-base * 1.15;

/* $display1-size is used as the upper level of the .hero-headline font size on wide monitors. */
$display1-size: 5rem;


/** GOOGLE FONTS
    https://fonts.google.com/share?selection.family=Nanum%20Gothic:wght@400;700
    http://google-webfonts-helper.herokuapp.com/fonts/nanum-gothic?subsets=korean,latin

    Nanum Gothic (latin + korean):
      - Regular (400)
      - Bold (700)
 */
@font-face {
  font-family: 'Nanum Gothic';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: local(''),
       url('/assets/fonts/korean/nanum-gothic-v21-latin_korean-regular.woff2') format('woff2'),
       url('/assets/fonts/korean/nanum-gothic-v21-latin_korean-regular.woff') format('woff');
}
@font-face {
  font-family: 'Nanum Gothic';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: local(''),
       url('/assets/fonts/korean/nanum-gothic-v21-latin_korean-700.woff2') format('woff2'),
       url('/assets/fonts/korean/nanum-gothic-v21-latin_korean-700.woff') format('woff');
}


@import 'main';

@media (max-width: 960px) {
  .hero-headline { font-size: 8.35vw }
}
